#canvasContainer {
    position: relative;
    width: 300px; /* Set your desired canvas width */
    height: 300px; /* Set your desired canvas height */
    border: 1px solid black;
    overflow: hidden; /* Ensure content beyond canvas size is hidden */
  }
  #canvas {
    width: 100%;
    height: 100%;
    image-rendering: pixelated; /* Prevent blurriness */
    background-color: rgb(0, 0, 0);
  }