.user-profile {
  padding: 20px;
}
.profile-image {
  height: 200px;
  width: 200px;
  background-color: var(--mid-theme-color);
  border-radius: 100px;
  position: relative;
  overflow: hidden;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.profile-image:hover .change-profile {
  display: block;
}
.profile-image .change-profile {
  text-align: center;
  padding: 10px 20px;
  display: none;
  position: absolute;
  width: 100%;
  height: 35%;
  background: #0000005c;
  color: #fff;
  bottom: 0;
}
.profile-image .change-profile input, .edit-image input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 1;
}
.edit-image{
  width: fit-content;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.user-info-box {
  padding: 10px;
  background-color: #f1f1f1;
  margin-top: 9px;
}
.edit-buttons{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.image-editor {
  position: fixed;
  width: 200px;
  padding: 10px;
  background-color: #f1f1f1;
  
}