.blog-image {
    width: 150px;
}

.blog-status {
    /* width: 104px; */
    border: 1px solid #bbb;
    background-color: #f0f0f7;
    font-size: 12px;
    border-radius: 4px;
    padding: 3px 9px;
    font-weight: bold;
    margin-top: 0;
    margin-left: auto;
    margin-right: 12px;
    height: 25px;
    margin-bottom: 10px;
}

.blog-status span {
    height: 9px;
    width: 15px;
    border-radius: 15px;
    background-color: #c80000;
    display: block;
    margin-left: 5px;
}

.blog-status span.published {
    background-color: rgb(2, 143, 96);
}

.blog-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.blog-description {
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 88px;
}

/* .blog-edit-button {
    border: 2px solid #5113a3;
    background-color: transparent;
    color: #5113a3;
    border-radius: 4px;
} */
.publish-status-button {
    padding: 2px 7px;
    border: 2px solid rgb(2, 143, 96);
    background-color: rgb(2, 143, 96);
    color: #fff;
    border-radius: 4px;
}
.unpublish-status-button {
    border: 2px solid #971515;
    height: 30px;
    padding: 1px 20px;
    margin-bottom: 0;
    background: transparent;
    border-radius: 4px;
}

.edit-blog-button {
    border: 2px solid #584fe4;
    height: 30px;
    padding: 1px 20px;
    margin-bottom: 0;
    background: transparent;
    border-radius: 4px;
}
.delete-blog-button {
    border: 2px solid #e15353;
    background-color: #e15353;
    color: #fff;
    padding: 2px 7px;
    border-radius: 4px;
}
.confirm-modal-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000092;
}
.confirm-modal {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    top: 30%;
    width: 300px;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
}
.confirm-modal button {
    margin-right: 10px;
}
@media (max-width: 600px) {
    .blog-image {
        width: 85px;
        margin-right: -10px;
    }
}