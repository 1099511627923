.page-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.page-container .navbar-container {
  width: 100%;
  flex-grow: 0;
  flex-wrap: 1;
}

.page-container .page-area-conatiner {
  height: 100%;
}

.page-area {
  /* padding-top: 60px; */
  overflow: auto;
  max-height: calc(100vh - 60px);
  padding: 20px 20px;
}

.page-container .sidebar {
  width: 250px;
  height: 100%;
  background-color: var(--soft-theme-color);
  box-shadow: 2px 0px 3px 0px #00000012;
}

.navbar {
  height: 60px;
  background-color: var(--soft-theme-color);
}
.navbar .container-fluid{
  display: block;
}
.menu {
  /* margin-bottom: 10px; */
  font-size: 15px;
}

.menu ul {
  margin: 0;
  padding: 0;
}

.menu ul li {
  padding: 10px;
  width: 100%;
  list-style: none;
  border-bottom: 1px solid #9899ac2f;
}

.menu ul li a {
  color: unset;
  text-decoration: unset;
}

.menu ul li i {
  width: 25px;
}

.container-box {
  padding-top: 40px;
}

.dashbaoard-card {
  position: relative;
  height: 130px;
  color: inherit;
  /* font-size: 25px; */
  padding: 10px;
  /* background-color: #0a0a0a; */
  box-shadow: var(--box-shadow);
  border: unset;
}

.dashbaoard-card img {
  position: absolute;
  bottom: 0;
  height: 100%;
  right: -10px;
}

.card-content-1 {
  background-color: #c65386;
  background: linear-gradient(100deg, #c65386, #c653869c);
}

.card-content-2 {
  background-color: #5ac67d;
  background: linear-gradient(100deg, #5ac67d, #5ac67d9c);
}

.card-content-3 {
  background-color: #cb5f7a;
  background: linear-gradient(100deg, #cb5f7a, #cb5f7a9c);
}

.card-content-4 {
  background-color: #5cc2ff;
  background: linear-gradient(100deg, #5cc2ff, #5cc2ff9c);
}

.dashbaoard-card p {
  z-index: 1;
  margin-bottom: 0;
  display: inline;
  width: fit-content;
  /* font-weight: bold; */
}

.dashbaoard-card span {
  font-size: 25px;
  color: #fff;
}

.blog-edit-button {
  border-radius: 5px;
  padding: 7px 20px;
  margin-bottom: 15px;
}

.blog-edit-button:disabled {
  opacity: 0.5;
}

.save-blog-button {
  background-color: #0036cc;
  color: #fff;
  border: 2px solid #0036cc;
}

.create-blog-button {
  background-color: #7300cc;
  color: #fff;
  border: 2px solid #7300cc;
}

.preview-blog-button {
  background-color: transparent;
  border: 2px solid #7300cc;
}

.publish-blog-button {
  background-color: #00cc85;
  color: #fff;
  border: 2px solid #00cc85;
}
.unpublish-blog-button {
  background-color: #cc0000;
  color: #fff;
  border: 2px solid #cc0000;
}
/* .dropdown {
    display: none;
    background-color: #fff;
    padding: 10px;
    position: absolute;
    top: 30px;
}
.dropdown-button:hover > .dropdown {
    display: block;
    position: relative;

} */
.login-form {
  padding: 30px 112px 0 112px;
  width: 480px;
  height: 264px;
}
.login-form img {
  height: 90px;
  margin-bottom: 51px;
}
.login-form h3 {
  margin-bottom: 20px;
  font-size: 22px;
}
.login-side {
  background: #34e89e;
  background-image: url("../images/iamge.jpeg");
  background-position: center;
  background-size: cover;
  filter: brightness(86%);
  border-radius: 80px 0 0;
  filter: hue-rotate(28deg) brightness(0.8);
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #0f3443, #34e89e); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #0f3443, #34e89e); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 100vh;
  padding: 20px;
}
.login-side h1 {
  color: #00ff80;
  font-size: 60px;
  text-shadow: 2px 2px 5px rgb(0 0 0 / 89%);
}
.login-side img {
  width: 80px;
  float: right;
}

.login-side .text {
  margin-top: 48vh;
  /* background-color: #1f202074; */
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  /* padding: 10px; */
  width: 80%;
}
.login-side h2 {
  color: #ffffff;
  font-size: 60px;
  font-weight: bolder;
  margin-bottom: 22px;
  letter-spacing: 2px;
  text-shadow: 2px 2px 8px rgb(0 0 0 / 89%);
}
.login-side h3 {
  color: #fff;
  text-shadow: 6px 2px 10px rgb(0 0 0 / 100%);
}
.brand-container {
  display: flex;
  flex-wrap: nowrap;
}
.brand-image {
  height: 36px;
  margin-right: 10px;
}
.brand-text {
  font-size: 27px;
  font-weight: 700;
  margin: 0;
  margin-top: 5px;
}
.nav-profile-image {
  height: 45px;
  border-radius: 50%;
}
.nav-user-name {
  font-size: 14px;
}
.multi-select-input {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  background-color: var(--input-background);
}

.tag {
  background-color: #eee;
  border-radius: 3px;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
}

.tag button {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

input {
  border: none;
  flex-grow: 1;
  min-width: 100px;
  outline: none;
}

.dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  height: 150px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  display: block;
  width: fit-content;
  overflow-y: auto;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #eee;
}
.dropdown li.selected {
  background-color: rgb(202, 201, 209);
}
.multi-select-input {
  width: 100%;
  position: relative; /* Ensures the dropdown is positioned relative to the container */
}

.tag {
  background-color: #eee;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.tag button {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.inline-input {
  flex-grow: 1;
  border: none;
  outline: none;
  min-width: 120px; /* Prevent input from collapsing */
  background-color: transparent;
}

/* Ensure the dropdown appears below the input */
.dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  top: 100%; /* Position dropdown at the bottom of the input */
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #eee;
}
/* Google Login Container */
.google-login-container {
  margin-top: 20px; /* Adjust margin as needed */
}

.custom-google-button {
  background-color: #ffffff;
  color: #3e4968;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition:
    width 0.3s ease,
    background-color 0.3s ease;
  overflow: hidden;
  width: 250px;
  box-shadow: 0 0 8px 0 #00000017;
}

.custom-google-button:hover {
  width: 300px; /* Width on hover */
  background-color: #151515; /* Darker blue color on hover */
  color: #ffffff;
}

.google-logo {
  margin-right: 10px; /* Space between logo and text */
}
.sidebar-menu {
  display: none;
}
.sidebar-list {
  display: none;
}
.edit-button-group {
  display: none;
}
@media (max-width: 600px) {
  body {
    font-size: 15px;
  }
  .user-profile-name {
    display: none;
  }
  .user-profile-image {
    margin-left: auto;
  }
  .page-container .sidebar {
    display: none;
  }
  .page-area {
    padding: 12px 10px;
  }
  .card-header {
    padding: 10px 9px;
  }
  .card-body {
    padding: 10px;
  }
  .card-block-header h5{
    margin-bottom: 0;
    font-size: 18px;
  }
  .sidebar-menu {
    display: flex;
    align-items: center;
  }
  .sidebar-menu img {
    height: 30px;
  }
  .sidebar-list {
    display: block;
    position: fixed;
    width: 100vw;
    height: calc(100vh - 61px);
    left: 0;
    top: 61px;
    background-color: #fff;
  }
  .edit-button-group {
    display: block;
    margin-top: 20px;
  }
  .blog-edit-button {
    margin-bottom: 10px;
    padding: 7px 10px;
    margin-right: -10px;
  }
  
}
